export default {
  id: '',
  orderSeq: 0,
  title: '',
  body: '',
  color: '#111111',
  bgColor: '#ffffff80',
  onDashboard: false,
  updatedAt: new Date(),
  createdAt: new Date()
}
