import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'
import { isAuthenticated } from '@/modules/Auth/Store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 })
})

const DEFAULT_TITLE = 'Welcome';

router.beforeEach((to, from, next) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  })

  if (to.name !== 'SignIn' && isAuthenticated() === false) {
    next({ name: 'SignIn' })
  } else if (isAuthenticated() && ['SignIn', 'register'].indexOf(to.name) !== -1) {
    next('/')
  } else {
    next()
  }
})

// const isAuthenticated = false

/* router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !isAuthenticated) {
    next({ name: 'login' })
  } else if (isAuthenticated && ['login', 'register'].indexOf(to.name) !== -1) {
    next('/')
  } else {
    next()
  }
}) */

export default router
