<template>
  <div>
    <ul class="errors" v-if="errors.length > 0">
      <li v-for="error in errors" :key="error" class="errors__message">{{ error }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Errors',
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
.errors {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px 10px 10px 30px;
  width: 100%;
  border-radius: 4px;
  background: rgba(255, 178, 178, 0.7);
  color: #650707;

  &__message {
    margin-bottom: 5px;
    line-height: 1.2;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
