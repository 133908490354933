/*
*   store/modules/users.module.js
*/

// import the api endpoints
import { listAll, add, edit, remove, reorder } from "@/api/stickers.api"
import DefaultSticker from '../Model/Sticker'

const sortByOrderSeq = function (a, b) {
  if (a.orderSeq > b.orderSeq) {
    return 1
  }

  if (a.orderSeq < b.orderSeq) {
    return -1
  }

  return 0
}

const state = {
  stickers: [],
  isLoading: false,
  search: ''
}

const getters = {
  stickers: (state) => {
    return state.stickers
      .filter(o => {
        if (state.search === '') {
          return true
        }

        return o.title.toLowerCase().includes(state.search.toLowerCase()) || o.body.toLowerCase().includes(state.search.toLowerCase())
      })
      .sort(sortByOrderSeq)
  },
  favoritedStickers: (state) => {
    return state.stickers
      .filter(o => {
        return o.onDashboard === true
      })
      .sort(sortByOrderSeq)
  },
  isLoading: (state) => state.isLoading,
  search: (state) => state.search
}

const actions = {
  loadAll ({ commit }) {
    commit('isLoading', true)

    return listAll()
      .then(stickers => {
        commit('isLoading', false)
        commit('setStickers', stickers)
      })
      .finally(() => commit('isLoading', false))
  },
  add ({ commit }, sticker) {
    commit('isLoading', true)

    return add(sticker)
      .then(result => {
        if (result.errors.length === 0) {
          commit('isLoading', false)
          commit('add', sticker)
        }
      })
      .finally(() => commit('isLoading', false))
  },
  edit ({ commit }, sticker) {
    commit('isLoading', true)

    return edit(sticker)
      .then(result => {
        if (result.errors.length === 0) {
          commit('isLoading', false)
          commit('edit', sticker)
        }
      })
      .finally(() => commit('isLoading', false))
  },
  remove ({ commit }, sticker) {
    commit('isLoading', true)

    return remove(sticker)
      .then(result => {
        if (result.errors.length === 0) {
          commit('isLoading', false)
          commit('remove', sticker)
        }
      })
      .finally(() => commit('isLoading', false))
  },
  reorder ({ commit, state }, stickers) {
    if (stickers.length !== state.stickers.length) {
      return
    }

    const orders = stickers.map((el, index) => {
      return {
        id: el.id,
        orderSeq:index
      }
    })

    commit('isLoading', true)

    return reorder(orders)
      .then(result => {
        if (result.length > 0) {
          commit('isLoading', false)
          commit('reorder', result)
        }
      })
      .finally(() => commit('isLoading', false))
  }
}

const mutations = {
  isLoading (state, newStatus) {
    state.isLoading = newStatus
  },
  setSearch (state, newState) {
    state.search = newState
  },
  setStickers (state, data) {
    state.stickers = data.map(el => {
      return { ...DefaultSticker, ...el, createdAt: new Date(el.createdAt), updatedAt: new Date(el.updatedAt) }
    })
  },
  add (state, sticker) {
    const newSticker = { ...DefaultSticker, ...sticker, createdAt: new Date(sticker.createdAt), updatedAt: new Date(sticker.updatedAt) }
    state.stickers.push(newSticker)
  },
  edit (state, sticker) {
    const foundSticker = state.stickers.find(obj => {
      return obj.id === sticker.id
    })

    if (foundSticker !== undefined) {
      Object.assign(foundSticker, sticker)
    }
  },
  remove(state, sticker) {
    state.stickers = state.stickers.filter(obj => {
      return obj.id !== sticker.id
    })
  },
  reorder (state, stickers) {
    if (stickers.length !== state.stickers.length) {
      return
    }

    stickers.forEach((el, index) => {
      const foundSticker = state.stickers.find(sticker => sticker.id === el.id);

      if (foundSticker !== undefined) {
        Object.assign(foundSticker, { orderSeq: index })
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
