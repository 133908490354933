<template>
  <ul class="menu">
    <loading :active="isLoading" :is-full-page="false" :opacity="0" :color="'#007bff'" />

    <li class="menu__item">
      <router-link :to="{ name: 'Index'}"><i class="fas fa-solar-panel"></i> Dashboard</router-link>
    </li>
    <li class="menu__item">
      <router-link :to="{ name: 'Bookmarks'}"><i class="far fa-bookmark"></i> Bookmarks</router-link>
    </li>
    <li class="menu__item">
      <router-link :to="{ name: 'Stickers'}"><i class="far fa-sticky-note"></i> Stickers</router-link>
    </li>
    <li v-for="(note) in notes" :key="note.id" class="menu__item menu__note">
      <router-link :to="{ name: 'Note', params: { id: note.id }}">
        <i class="far fa-file-alt"></i> {{ note.title }}

        <span class="menu__note__remove" @click.prevent="deleteNote(note)"><i class="fa fa-times"></i></span>
      </router-link>
    </li>
    <li class="menu__item menu__add" @click="createNote()"><a>+</a></li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay'

export default {
  name: 'HeaderMenu',
  components: {
    Loading
  },
  props: {},
  data () {
    return {
      menu: [
        { id: 1, name: 'Note' }
      ]
    }
  },
  mounted () {
    this.loadAllNotes()
  },
  computed: {
    ...mapGetters('Notes', ['notes', 'isLoading'])
  },
  methods: {
    ...mapActions('Notes', ['loadAllNotes', 'addNote', 'removeNote']),
    createNote () {
      this.addNote()
        .then(note => {
          if (note.errors.length === 0) {
            this.$router.push({ name: 'Note', params: { id: note.id } })
          }
        })
    },
    deleteNote (note) {
      if (!confirm('Are you want remove this note?')) {
        return
      }

      this.removeNote(note)
        .then(note => {
          if (note.errors.length === 0 && this.$route.name === 'Note') {
            this.$router.push({ name: 'Index' })
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
.menu {
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  flex-wrap: wrap;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(100px);

  &__item {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 250px;
    cursor: pointer;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);

    a {
      display: block;
      flex-grow: 1;
      padding: 10px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.router-link-exact-active {
        background: rgba(155, 155, 255, 0.2);
      }
    }

    &:hover, .menu__active {
      cursor: pointer;
      background: rgba(155, 155, 255, 0.2);

      a {
        color: #fff;
      }
    }
  }

  &__note {
    max-width: 300px;

    a {
      position: relative;
      padding-right: 30px;
    }

    &__remove {
      position: absolute;
      right: 10px;
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;
      line-height: 1;
      padding: 4px;
      border-radius: 100%;
      font-size: 12px;
      color: #dddddd;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  &__add {
    font-size: 24px;
    line-height: 1;
    flex: 0 0 80px;
    width: 80px;
    max-width: 80px;
    min-width: 0;
  }
}
</style>
