export default {
  id: '',
  groupId: '',
  orderSeq: 0,
  dashSeq: 0,
  title: '',
  url: '',
  icon: '',
  color: '#111111',
  bgColor: '#ffffff80',
  onDashboard: false,
  updatedAt: new Date(),
  createdAt: new Date()
}
