import httpClient from './httpClient';

const loadBookmarks = () => {
  return httpClient.post('/bookmarks/list/')
    .then(response => {
      let bookmarks = []

      if (typeof response.data.bookmarks !== 'undefined' && Array.isArray(response.data.bookmarks)) {
        bookmarks = response.data.bookmarks
      }

      return bookmarks
    })
}

const addBookmark = (bookmark) => {
  return httpClient.post('/bookmarks/add/', bookmark)
    .then(response => {
      if (typeof response.data.bookmark === 'undefined') {
        throw new Error('Request failed')
      }

      const bookmark = response.data.bookmark

      if (bookmark.errors.length > 0) {
        throw new Error(bookmark.errors.shift())
      }

      return bookmark
    })
}

const editBookmark = (bookmark) => {
  return httpClient.post('/bookmarks/edit/', bookmark)
    .then(response => {
      if (typeof response.data.bookmark === 'undefined') {
        throw new Error('Request failed')
      }

      const bookmark = response.data.bookmark

      if (bookmark.errors.length > 0) {
        throw new Error(bookmark.errors.shift())
      }

      return bookmark
    })
}

const removeBookmark = (bookmark) => {
  return httpClient.post('/bookmarks/delete/', bookmark)
    .then(response => {
      if (typeof response.data.bookmark === 'undefined') {
        throw new Error('Request failed')
      }

      const bookmark = response.data.bookmark

      if (bookmark.errors.length > 0) {
        throw new Error(bookmark.errors.shift())
      }

      return bookmark
    })
}

const reorderBookmarks = (bookmarks) => {
  return httpClient.post('/bookmarks/reorder/', { orders: bookmarks })
    .then(response => {
      if (typeof response.data.bookmarks === 'undefined') {
        throw new Error('Request failed')
      }

      return response.data.bookmarks.filter(el => el.errors.length === 0)
    })
}

const reorderDashBookmarks = (bookmarks) => {
  return httpClient.post('/bookmarks/reorder-dash/', { orders: bookmarks })
    .then(response => {
      if (typeof response.data.bookmarks === 'undefined') {
        throw new Error('Request failed')
      }

      return response.data.bookmarks.filter(el => el.errors.length === 0)
    })
}

const loadGroups = () => {
  return httpClient.post('/bookmarks/categories/list/')
    .then(response => {
      let groups = []

      if (typeof response.data.groups !== 'undefined' && Array.isArray(response.data.groups)) {
        groups = response.data.groups
      }

      return groups
    })
}

const addGroup = (group) => {
  return httpClient.post('/bookmarks/categories/add/', group)
    .then(response => {
      if (typeof response.data.group === 'undefined') {
        throw new Error('Request failed')
      }

      const group = response.data.group

      if (group.errors.length > 0) {
        throw new Error(group.errors.shift())
      }

      return group
    })
}

const editGroup = (group) => {
  return httpClient.post('/bookmarks/categories/edit/', group)
    .then(response => {
      if (typeof response.data.group === 'undefined') {
        throw new Error('Request failed')
      }

      const group = response.data.group

      if (group.errors.length > 0) {
        throw new Error(group.errors.shift())
      }

      return group
    })
}

const removeGroup = (group) => {
  return httpClient.post('/bookmarks/categories/delete/', group)
    .then(response => {
      if (typeof response.data.group === 'undefined') {
        throw new Error('Request failed')
      }

      const group = response.data.group

      if (group.errors.length > 0) {
        throw new Error(group.errors.shift())
      }

      return group
    })
}

const reorderGroups = (groups) => {
  return httpClient.post('/bookmarks/categories/reorder/', { orders: groups })
    .then(response => {
      if (typeof response.data.groups === 'undefined') {
        throw new Error('Request failed')
      }

      return response.data.groups.filter(el => el.errors.length === 0)
    })
}

export {
  loadBookmarks,
  addBookmark,
  editBookmark,
  removeBookmark,
  reorderBookmarks,
  reorderDashBookmarks,

  loadGroups,
  addGroup,
  editGroup,
  removeGroup,
  reorderGroups
}
