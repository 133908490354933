import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '../modules/Auth/Store'
import Settings from '../modules/Settings/Store'
import Stickers from '../modules/Stikers/Store'
import Notes from '../modules/Notes/Store'
import Bookmarks from '../modules/Bookmarks/Store'
import Account from '../modules/Account/Store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Auth: Auth,
    Settings: Settings,
    Stickers: Stickers,
    Notes: Notes,
    Bookmarks: Bookmarks,
    Account: Account
  }
})
