import httpClient from './httpClient';

const listAll = () => {
  return httpClient.post('/notes/list/')
    .then(response => {
      let notes = []

      if (typeof response.data.notes !== 'undefined' && Array.isArray(response.data.notes)) {
        notes = response.data.notes
      }

      return notes
    })
}

const add = (note) => {
  return httpClient.post('/notes/add/', note)
    .then(response => {
      if (typeof response.data.note === 'undefined') {
        throw new Error('Request failed')
      }

      let note = response.data.note

      if (note.errors.length > 0) {
        throw new Error(note.errors.shift())
      }

      return note
    })
}

const view = (id) => {
  return httpClient.post('/notes/view/', { id: id })
    .then(response => {
      if (typeof response.data.note === 'undefined') {
        throw new Error('Request failed')
      }

      let note = response.data.note

      if (note.errors.length > 0) {
        throw new Error(note.errors.shift())
      }

      return note
    })
}

const edit = (note) => {
  return httpClient.post('/notes/edit/', note)
    .then(response => {
      if (typeof response.data.note === 'undefined') {
        throw new Error('Request failed')
      }

      let note = response.data.note

      if (note.errors.length > 0) {
        throw new Error(note.errors.shift())
      }

      return note
    })
}

const remove = (note) => {
  return httpClient.post('/notes/delete/', note)
    .then(response => {
      if (typeof response.data.note === 'undefined') {
        throw new Error('Request failed')
      }

      let note = response.data.note

      if (note.errors.length > 0) {
        throw new Error(note.errors.shift())
      }

      return note
    })
}

export {
  listAll,
  add,
  view,
  edit,
  remove
}
