/*
 *   modules/Notes/Store/index.js
 */

// import the api endpoints
// import { listGroups, addGroup, deleteGroup } from "@/api/bookmarks.api"
// import { v4 as uuidv4 } from 'uuid';

const state = {
  groups: [],
  isLoading: false
}

const getters = {
  notes: (state) => state.groups,
  isLoading: (state) => state.isLoading
}

const actions = {

}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
