/*
 *   modules/Notes/Store/index.js
 */

// import the api endpoints
import { listAll, add, view, edit, remove } from "@/api/notes.api"
import { v4 as uuidv4 } from 'uuid';

const state = {
  notes: [],
  isLoading: false
}

const getters = {
  notes: (state) => state.notes,
  isLoading: (state) => state.isLoading
}

const actions = {
  loadAllNotes({ commit }) {
    commit('isLoading', true)

    return listAll()
      .then(notes => {
        commit('isLoading', false)
        commit('setNotes', notes)

        return notes
      })
      .finally(() => commit('isLoading', false))
  },
  loadNote({ commit }, id) {
    commit('isLoading', true)

    return view(id)
      .then(note => {
        commit('isLoading', false)

        return note
      })
      .finally(() => commit('isLoading', false))
  },
  addNote({ commit }) {
    const note = newNote()

    commit('isLoading', true)

    return add(note)
      .then(result => {
        if (result.errors.length === 0) {
          commit('isLoading', false)
          commit('add', note)
        }

        return result
      })
      .finally(() => commit('isLoading', false))
  },
  editNote({ commit }, note) {
    commit('isLoading', true)

    return edit(note)
      .then(result => {
        if (result.errors.length === 0) {
          commit('isLoading', false)
          commit('edit', note)

          return result
        }
      })
      .finally(() => commit('isLoading', false))
  },
  removeNote({ commit }, note) {
    commit('isLoading', true)

    return remove(note)
      .then(result => {
        if (result.errors.length === 0) {
          commit('isLoading', false)
          commit('remove', note)
        }

        return result
      })
      .finally(() => commit('isLoading', false))
  }
}

const mutations = {
  isLoading(state, newStatus) {
    state.isLoading = newStatus
  },
  setNotes(state, data) {
    data.sort(function (a, b) {
      if (a.orderSeq > b.orderSeq) {
        return 1;
      }

      if (a.orderSeq < b.orderSeq) {
        return -1;
      }

      return 0;
    });

    state.notes = data.map((el) => {
      el.updatedAt = new Date(el.updatedAt)
      el.createdAt = new Date(el.createdAt)

      return el
    });
  },
  add(state, note) {
    state.notes.push(note)
  },
  edit(state, note) {
    const foundNote = state.notes.find(obj => {
      return obj.id === note.id
    })

    if (foundNote !== undefined) {
      Object.assign(foundNote, note)
    }
  },
  remove(state, note) {
    state.notes = state.notes.filter(obj => {
      return obj.id !== note.id
    })
  }
}

/**
 * Create new note
 *
 * @returns {{orderSeq: number, createdAt: Date, id: (*|string), title: string, updatedAt: Date}}
 */
const newNote = () => {
  const newNoteNumber = state.notes.length + 1;

  return {
    id: uuidv4(),
    title: `New note #${newNoteNumber}`,
    orderSeq: newNoteNumber,
    updatedAt: new Date(),
    createdAt: new Date()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
