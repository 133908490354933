import axios from 'axios';
import config from '../configs/api'
import router from '@/router'
import { SignOut } from '@/modules/Auth/Store'

const clientConfig = {
  baseURL: config.baseUrl,
  //timeout: 15000,
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
    'X-Requested-With': 'XMLHttpRequest'
  }
}

const httpClient = axios.create(clientConfig);

const setHeader = (headerName, value) => {
  if (value !== null) {
    // @see hack https://stackoverflow.com/a/25333702
    Object.assign(httpClient.defaults.headers, { [headerName]: value })
  }
}

const removeHeader = (headerName) => {
  if (typeof httpClient.defaults.headers[headerName] !== undefined) {
    delete httpClient.defaults.headers[headerName]
  }
}

// set auth token from session storage
const authToken = window.sessionStorage.getItem('apiToken')
const encryptToken = window.sessionStorage.getItem('encryptToken')

setHeader(config.authHeaderName, authToken)
setHeader(config.encryptHeaderName, encryptToken)

httpClient.interceptors.response.use(response => {
  return Promise.resolve(response)
}, error => {
  if (error.response.status === 401 && router.currentRoute.name !== 'SignIn') {
    removeHeader(config.authHeaderName)
    removeHeader(config.encryptHeaderName)
    SignOut()
  }

  return Promise.reject(error)
})

export default httpClient;

export {
  setHeader,
  removeHeader
}
