
//import Page404 from '../modules/Errors/Page404.vue'

export const routes = [
  {
    path: '/',
    component: () => import(
      /* webpackChunkName: "dashboard" */
      '../modules/App/Index.vue'
    ),
    name: 'Index',
    meta: {
      default: true,
      title: 'Organizer - Dashboard'
    }
  },
  {
    path: '/login',
    component: () => import(
      /* webpackChunkName: "auth" */
      '../modules/Auth/SignIn.vue'
    ),
    name: 'SignIn',
    meta: {
      default: false,
      title: 'Sing in to service'
    }
  },
  {
    path: '/stickers',
    name: 'Stickers',
    component: () => import(
      '../modules/Stikers/Index.vue'
    ),
    meta: {
      title: 'Organizer - Stickers',
    }
  },
  {
    path: '/note/:id',
    name: 'Note',
    component: () => import(
      '../modules/Notes/Index.vue'
    ),
    meta: {
      title: 'Organizer - Note',
    }
  },
  {
    path: '/bookmarks',
    name: 'Bookmarks',
    component: () => import(
      '../modules/Bookmarks/Index.vue'
      ),
    meta: {
      title: 'Organizer - Bookmarks',
    }
  }
  /*{
    path: '/404.html',
    name: 'NotFound',
    alias: '*',
    component: Page404
  }*/
]
