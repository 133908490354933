<template>
  <div id="app" class="app">
    <header class="header">
      <HeaderMenu v-if="isAuth === true"/>
    </header>
    <div class="content">
      <router-view/>
    </div>

    <account-menu v-if="isAuth === true"/>
  </div>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu'
import { mapGetters } from 'vuex'
import AccountMenu from '@/components/AccountMenu'
import 'vue-loading-overlay/dist/vue-loading.css'

document.body.addEventListener('mouseup', () => {
  document.body.style.cursor = 'auto'
})

export default {
  components: { AccountMenu, HeaderMenu },
  computed: {
    ...mapGetters('Auth', ['isAuth'])
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,700&display=swap');

html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  display: flex;
  margin: 0;
  width: 100%;
  min-height: 100%;
  background: url("assets/3colors.webp") no-repeat center center fixed;
  background-size: cover;
  font-family: Roboto, 'sans-serif';
  color: #fff;
  font-size: 1rem;
  line-height: 1.5;
}

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}

.content {
  flex-grow: 1;
  width: 100%;
  margin: 15px auto 0;
  padding: 0 5px;

  @media screen and (min-width: 992px) {
    padding: 0 20px;
  }
}

input::placeholder, textarea::placeholder {
  color: #444;
}

input:focus,
button:focus {
  box-shadow: 0 0 0 3px rgba(255, 228, 132, 0.25);
}

html ::-webkit-scrollbar {
  background: #15253c;
  width: 5px;
  height: 5px;
}

.sticker ::-webkit-scrollbar {
  background: rgba(255, 255, 255, 0.2);
}

html ::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 16px;
}

html ::-webkit-scrollbar-track {
  background-color: transparent;
}

html ::-webkit-scrollbar-button {
  display: none;
}

p {
  margin: 0;
}

*:focus {
  outline: none;
}

button {
  &:hover {
    cursor: pointer;
  }
}

.danger {
  background: #d9194f !important;
}

.tox-tinymce {
  border-radius: 3px !important;
}

.tox-notifications-container, .tox-statusbar {
  display: none !important;
}

.tox .tox-edit-area__iframe, .tox .tox-toolbar {
  background: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(25px);
}

.modal .tox .tox-edit-area__iframe, .modal .tox .tox-toolbar {
  backdrop-filter: blur(0px);
}

.tox-toolbar__primary {
  background: transparent !important;
}

.tox .tox-toolbar-overlord {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.tox-tinymce, .tox .tox-toolbar-overlord {
  border: none !important;
}

.vm--modal {
  background-color: unset!important;
  box-shadow: none!important;
  overflow: visible!important;
}

.vm--overlay {
  background-color: rgba(0, 0, 0, 0.8)!important;
  -webkit-backdrop-filter: blur(40px)!important;
  backdrop-filter: blur(40px)!important;
  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    .vm--overlay {
      -webkit-backdrop-filter: blur(2em);
      backdrop-filter: blur(2em);
    }
  }
}

.form-row {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
  line-height: 1.2;
}

.form-control {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px 20px;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, .5);
  font-size: 20px;
  color: #111;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #555;
  }
}

.btn {
  padding: 5px 30px;
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, .5);

  &-danger {
    background: rgba(255, 0, 100, .5);

    &:hover {
      background: darken(rgba(255, 0, 100, .5), 15%);
    }
  }

  &-success {
    background: rgba(0, 255, 100,.5);

    &:hover {
      background: darken(rgba(0, 255, 100, .5), 15%);
    }
  }
}

</style>
