import httpClient from './httpClient'
import config from '../configs/api'
import { setHeader, removeHeader } from './httpClient'

const auth = (password) => {
  return httpClient.post('/auth/', { action: 'auth', password: password })
    .then(response => {
      if (typeof response.data.token !== 'undefined') {
        setHeader(config.authHeaderName, response.data.token)
        setHeader(config.encryptHeaderName, password)
      }

      return response
    })
}

const logout = () => {
  return httpClient.post('/logout/')
    .then(response => {
      removeHeader(config.authHeaderName)
      removeHeader(config.encryptHeaderName)

      return response
    })
}

export {
  auth,
  logout
}
