/*
*   store/modules/users.module.js
*/

// import the api endpoints
import { auth, logout } from "@/api/auth.api"
// import router from '@/router'

const SignOut = () => {
  state.isAuth = false
  sessionStorage.removeItem('apiToken')
  sessionStorage.removeItem('encryptToken')
  window.location.href = '/login'
}

const state = {
  isAuth: false,
  isLoading: false
}

const getters = {
  isAuth: state => state.isAuth,
  isLoading: state => state.isLoading
}

const actions = {
  signIn({ commit }, password) {
    return new Promise((resolve, reject) => {
      commit('isLoading', true)

      auth(password)
        .then(response => {
          try {
            if (typeof response.data.token !== 'undefined') {
              window.sessionStorage.setItem('apiToken', response.data.token)
              window.sessionStorage.setItem('encryptToken', password)

              commit('authenticate', true)
              resolve(true)
            }

            reject(new Error('Unauthorised'))
          } catch (error) {
            reject(error)
          }
        })
        .catch((error) => {
          if (typeof error.response.status !== 'undefined' && error.response.status === 401) {
            reject(new Error('Wrong password'))
          }

          reject(new Error(error.response.statusText))
        })
        .finally(() => commit('isLoading', false))
    })
  },
  logout ({ commit }) {
    commit('isLoading', true)

    logout()
      .then(() => {
        commit('logout')
      })
      .finally(() => commit('isLoading', false))
  }
}

const mutations = {
  isLoading (state, newStatus) {
    state.isLoading = newStatus
  },
  authenticate(state, authState) {
    state.isAuth = authState;
  },
  logout () {
    SignOut()
  }
}

const isAuthenticated = () => {
  if (window.sessionStorage.getItem('apiToken') !== null) {
    state.isAuth = true
  }

  return state.isAuth === true
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export { isAuthenticated, SignOut }
