/*
 *   modules/Notes/Store/index.js
 */

// import the api endpoints
import {
  addBookmark,
  addGroup,
  editBookmark,
  editGroup,
  loadBookmarks,
  loadGroups,
  removeBookmark,
  removeGroup,
  reorderBookmarks,
  reorderDashBookmarks,
  reorderGroups
} from '@/api/bookmarks.api'
import DefaultGroup from '../Model/Group'
import DefaultBookmark from '../Model/Bookmark'
import Group from './group'

const sortByOrderSeq = function (a, b) {
  if (a.orderSeq > b.orderSeq) {
    return 1
  }

  if (a.orderSeq < b.orderSeq) {
    return -1
  }

  return 0
}

const sortByDashSeq = function (a, b) {
  if (a.dashSeq > b.dashSeq) {
    return 1
  }

  if (a.dashSeq < b.dashSeq) {
    return -1
  }

  return 0
}

const state = {
  groups: [],
  bookmarks: [],
  isLoading: false
}

const getters = {
  groups: (state) => state.groups,
  bookmarks: (state) => state.bookmarks,
  isLoading: (state) => state.isLoading,
  dashBookmarks: (state) => {
    const bookmarks = state.bookmarks.filter(b => b.onDashboard === true)

    bookmarks.sort(sortByDashSeq)

    return bookmarks
  },
  groupedBookmarks: (state) => {
    if (state.groups.length === 0) {
      return []
    }

    return state.groups.map(group => {
      const bookmarks = state.bookmarks.filter(bookmark => bookmark.groupId !== null && bookmark.groupId === group.id)

      bookmarks.sort(sortByOrderSeq)

      return Object.assign(group, { bookmarks: bookmarks })
    })
      .sort(sortByOrderSeq)
  },
  ungroupedBookmarks: (state) => {
    const bookmarks = state.bookmarks.filter(b => b.groupId === null)

    bookmarks.sort(sortByOrderSeq)

    return bookmarks
  }
}

const actions = {
  loadGroups ({ commit }) {
    commit('isLoading', true)

    return loadGroups()
      .then(groups => {
        commit('isLoading', false)
        commit('loadGroups', groups)

        return groups
      })
      .finally(() => commit('isLoading', false))
  },
  addGroup ({ commit, state }, newGroup) {
    const orderSeq = state.groups.length + 1
    Object.assign(newGroup, { orderSeq: orderSeq })

    commit('isLoading', true)

    return addGroup(newGroup)
      .then(result => {
        if (result.errors.length === 0) {
          commit('isLoading', false)
          commit('addGroup', newGroup)
        }

        return result
      })
      .finally(() => commit('isLoading', false))
  },
  editGroup ({ commit }, group) {
    commit('isLoading', true)

    return editGroup(group)
      .then(result => {
        if (result.errors.length === 0) {
          commit('isLoading', false)
          commit('editGroup', group)
        }
      })
      .finally(() => commit('isLoading', false))
  },
  removeGroup ({ commit }, group) {
    commit('isLoading', true)

    return removeGroup(group)
      .then(result => {
        if (result.errors.length === 0) {
          commit('isLoading', false)
          commit('removeGroup', result)
        }
      })
      .finally(() => commit('isLoading', false))
  },
  reorderGroups ({ commit }, groups) {
    const orders = groups.map((el, index) => {
      return {
        id: el.id,
        orderSeq: index
      }
    })

    commit('isLoading', true)

    return reorderGroups(orders)
      .then(result => {
        if (result.length > 0) {
          commit('isLoading', false)
          commit('reorderGroups', result)
        }
      })
      .finally(() => commit('isLoading', false))
  },
  loadBookmarks ({ commit }) {
    commit('isLoading', true)

    return loadBookmarks()
      .then(bookmarks => {
        commit('isLoading', false)
        commit('loadBookmarks', bookmarks)

        return bookmarks
      })
      .finally(() => commit('isLoading', false))
  },
  addBookmark ({ commit, state }, bookmark) {
    let orderSeq = state.bookmarks.filter(bookmark => bookmark.groupId === bookmark.groupId).length + 1
    const dashSeq = state.bookmarks.filter(bookmark => bookmark.onDashboard === true).length + 1

    bookmark = Object.assign(bookmark, { orderSeq: orderSeq, dashSeq: dashSeq })

    commit('isLoading', true)

    return addBookmark(bookmark)
      .then(result => {
        if (result.errors.length === 0) {
          commit('isLoading', false)
          commit('addBookmark', bookmark)
        }
      })
      .finally(() => commit('isLoading', false))
  },
  editBookmark ({ commit }, bookmark) {
    commit('isLoading', true)

    return editBookmark(bookmark)
      .then(result => {
        if (result.errors.length === 0) {
          commit('isLoading', false)
          commit('editBookmark', bookmark)
        }
      })
      .finally(() => commit('isLoading', false))
  },
  removeBookmark ({ commit }, bookmark) {
    commit('isLoading', true)

    return removeBookmark(bookmark)
      .then(result => {
        if (result.errors.length === 0) {
          commit('isLoading', false)
          commit('removeBookmark', bookmark)
        }
      })
      .finally(() => commit('isLoading', false))
  },
  reorderBookmarks ({ commit }, bookmarks) {
    const orders = bookmarks.map((el, index) => {
      return {
        id: el.id,
        orderSeq: index
      }
    })

    commit('isLoading', true)

    return reorderBookmarks(orders)
      .then(result => {
        if (result.length > 0) {
          commit('isLoading', false)
          commit('reorderBookmarks', result)
        }
      })
      .finally(() => commit('isLoading', false))
  },
  reorderDashBookmarks ({ commit }, bookmarks) {
    const orders = bookmarks.map((el, index) => {
      return {
        id: el.id,
        dashSeq: index
      }
    })

    commit('isLoading', true)

    return reorderDashBookmarks(orders)
      .then(result => {
        if (result.length > 0) {
          commit('isLoading', false)
          commit('reorderDashBookmarks', result)
        }
      })
      .finally(() => commit('isLoading', false))
  }
}

const mutations = {
  isLoading (state, newStatus) {
    state.isLoading = newStatus
  },
  loadGroups (state, data) {
    state.groups = data.map(el => {
      return { ...DefaultGroup, ...el }
    })
  },
  loadBookmarks (state, data) {
    state.bookmarks = data.map(el => {
      return { ...DefaultBookmark, ...el, createdAt: new Date(el.createdAt), updatedAt: new Date(el.updatedAt) }
    })
  },
  addBookmark (state, bookmark) {
    state.bookmarks.push(bookmark)
  },
  editBookmark (state, bookmark) {
    const foundBookmark = state.bookmarks.find((el) => el.id === bookmark.id)

    if (foundBookmark !== undefined) {
      Object.assign(foundBookmark, bookmark)
    }
  },
  removeBookmark (state, bookmark) {
    state.bookmarks = state.bookmarks.filter(el => {
      return el.id !== bookmark.id
    })
  },
  reorderBookmarks (state, bookmarks) {
    bookmarks.forEach((bookmark, index) => {
      const foundBookmark = state.bookmarks.find((el) => el.id === bookmark.id)

      if (foundBookmark !== undefined) {
        Object.assign(foundBookmark, { orderSeq: index })
      }
    })
  },
  addGroup (state, group) {
    state.groups.push(group)
  },
  editGroup (state, group) {
    const foundGroup = state.groups.find(obj => {
      return obj.id === group.id
    })

    if (foundGroup !== undefined) {
      Object.assign(foundGroup, group)
    }
  },
  removeGroup (state, group) {
    state.groups = state.groups.filter(obj => {
      return obj.id !== group.id
    })
  },
  reorderGroups (state, groups) {
    state.groups = state.groups.map(group => {
      const foundGroupIndex = groups.findIndex(el => group.id === el.id)

      if (foundGroupIndex !== -1) {
        Object.assign(group, { orderSeq: foundGroupIndex })
      }

      return group
    })
  },
  reorderDashBookmarks (state, bookmarks) {
    bookmarks.forEach((el, index) => {
      const bookmark = state.bookmarks.find(bookmark => bookmark.id === el.id)

      if (bookmark !== undefined) {
        bookmark.dashSeq = index
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    Group: Group
  }
}
