/*
 *   modules/Notes/Store/index.js
 */

// import the api endpoints

const state = {
  isLoading: false
}

const getters = {
  isLoading: (state) => state.isLoading
}

const actions = {
}

const mutations = {
  isLoading(state, newStatus) {
    state.isLoading = newStatus
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
