<template>
  <div class="settings">
    <loading :active="isLoading" :is-full-page="false" :opacity="0" :color="'#007bff'" />

    <h2>Settings</h2>
    <section class="section">
      <header class="section__header">Password change</header>

      <errors :errors="errors"></errors>

      <div class="form-row">
        <label class="form-label">Old password</label>
        <input v-model="oldPassword" ref="autofocusInput" type="password" class="form-control" autofocus>
      </div>

      <div class="form-row">
        <label class="form-label">New password</label>
        <input v-model="newPassword" type="password" class="form-control">
        <input v-model="newPasswordConfirmation" type="password" class="form-control">
      </div>

      <footer class="section__footer">
        <button @click="$emit('close')" @keypress.enter="$emit('close')" @keypress.space="$emit('close')" class="btn btn-danger">Close</button>
        <button @click="changePasswordDecorator" @keypress.enter="changePasswordDecorator" @keypress.space="changePasswordDecorator" class="btn btn-success">Submit</button>
      </footer>
    </section>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import Errors from '@/components/Errors'
import Loading from 'vue-loading-overlay'

export default {
  name: 'SettingsForm',
  components: {
    Errors,
    Loading
  },
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      errors: []
    }
  },
  mounted() {
    this.$refs.autofocusInput.focus()
  },
  computed: {
    ...mapGetters('Account', ['isLoading'])
  },
  methods: {
    ...mapActions('Account', ['changePassword']),
    changePasswordDecorator () {
      this.errors = []

      if (this.oldPassword === '') {
        this.errors.push('You should enter the old password')
      }

      if (this.newPassword.length < 6) {
        this.errors.push('Password length must be greater than 6 symbols')
      }

      if (this.newPassword !== this.newPasswordConfirmation) {
        this.errors.push('New password mismatch')
      }

      if (this.oldPassword === this.newPassword) {
        this.errors.push('No need to change password')
      }

      if (this.errors.length > 0) {
        return
      }

      this.changePassword({
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        newPasswordConfirmation: this.newPasswordConfirmation
      })
        .then((errors) => {
          if (errors.length === 0) {
            this.oldPassword = ''
            this.newPassword = ''
            this.newPasswordConfirmation = ''

            this.$emit('close')

            return
          }

          errors.forEach(el => this.errors.push(el))
        })
        .catch(e => this.errors.push(e.message))
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.section {
  width: 100%;
  margin-bottom: 40px;

  &__header {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: left;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
