import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min'

import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal'
import Clipboard from 'v-clipboard'

import App from './App.vue'

import './assets/scss/main.scss'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VModal)
Vue.use(Clipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
