<template>
  <div class="account-menu">
    <ul class="menu">
      <li class="menu__item" @click="openSettings"><i class="fas fa-user-cog"></i></li>
      <li class="menu__item -logout" @click="logout" title="Logout"><i class="fas fa-sign-out-alt"></i></li>
    </ul>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import SettingsForm from '../modules/Settings/SettingsForm'

export default {
  name: 'AccountMenu',
  methods: {
    ...mapActions('Auth', ['logout']),
    openSettings () {
      this.$modal.show(
          SettingsForm,
          {},
          {
            adaptive: true,
            width: '318px',
            height: 'auto'
          }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.account-menu {
  position: fixed;
  bottom: 0;
  left: 0;
}

.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  border-top-right-radius: 3px;
  border-top: 1px solid #505050;
  border-right: 1px solid #505050;

  &__item {
    border-left: 1px solid #505050;
    padding: 2px 8px;
    background: #222;
    color: #bbb;
    font-size: 12px;

    &:first-child {
      border-left: none;
    }

    &:hover {
      cursor: pointer;
      color: lighten(#bbb, 10%);
      background: darken(#222, 10%);
    }

    &.-logout {
      color: orange;

      &:hover {
        color: lighten(orange, 10%);
      }
    }
  }
}
</style>
