import httpClient, { setHeader } from './httpClient'
import config from '../configs/api'

const register = (password) => {
  return httpClient.post('/account/register/', {  action: 'register', password: password })
    .then(response => {
      if (typeof response.data.token !== 'undefined') {
        setHeader(config.authHeaderName, response.data.token)
        setHeader(config.encryptHeaderName, password)
      }

      return response
    })
}

const changePassword = (payload) => {
  return httpClient.post('/account/change-password/', payload)
    .then(response => {
      if (typeof response.data.upgradePassword === 'undefined') {
        throw new Error('Request failed')
      }

      setHeader(config.encryptHeaderName, payload.newPassword)
      window.sessionStorage.setItem('encryptToken', payload.newPassword)

      return response.data.upgradePassword
    })
}

export {
  register,
  changePassword
}
