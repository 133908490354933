/*
 *   modules/Account/Store/index.js
 */

// import the api endpoints
import { register, changePassword } from "@/api/account.api"

const state = {
  isLoading: false
}

const getters = {
  isLoading: (state) => state.isLoading
}

const actions = {
  changePassword({ commit }, payload) {
    commit('isLoading', true)

    return changePassword(payload)
      .then(result => {
        commit('isLoading', false)

        return result.errors.map(el => String(el))
      })
      .finally(() => commit('isLoading', false))
  },
  registration({ commit }, password) {
    commit('isLoading', true)

    return new Promise((resolve, reject) => {
      register(password)
        .then(response => {
          try {
            if (typeof response.data.error !== 'undefined') {
              reject(new Error(response.data.error))
            }

            if (typeof response.data.token !== 'undefined' && response.data.token.length === 32) {
              window.sessionStorage.setItem('apiToken', response.data.token)
              window.sessionStorage.setItem('encryptToken', password)

              resolve(true)
            }

            reject(new Error('Cannot create new desktop'))
          } catch (error) {
            reject(error)
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            reject(new Error('Wrong password'))
          }

          reject(new Error(error.response.statusText))
        })
        .finally(() => commit('isLoading', false))
    })
  },
}

const mutations = {
  isLoading(state, newStatus) {
    state.isLoading = newStatus
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
