import httpClient from './httpClient'

const listAll = () => {
  return httpClient.post('/stickers/list/')
    .then(response => {
      let stickers = []

      if (typeof response.data.stickers !== 'undefined' && Array.isArray(response.data.stickers)) {
        stickers = response.data.stickers
      }

      return stickers
    })
}

const add = (sticker) => {
  return httpClient.post('/stickers/add/', sticker)
    .then(response => {
      if (typeof response.data.sticker === 'undefined') {
        throw new Error('Request failed')
      }

      let sticker = response.data.sticker

      if (sticker.errors.length > 0) {
        throw new Error(sticker.errors.shift())
      }

      return sticker
    })
}

const edit = (sticker) => {
  return httpClient.post('/stickers/edit/', sticker)
    .then(response => {
      if (typeof response.data.sticker === 'undefined') {
        throw new Error('Request failed')
      }

      let sticker = response.data.sticker

      if (sticker.errors.length > 0) {
        throw new Error(sticker.errors.shift())
      }

      return sticker
    })
}

const remove = (sticker) => {
  return httpClient.post('/stickers/delete/', sticker)
    .then(response => {
      if (typeof response.data.sticker === 'undefined') {
        throw new Error('Request failed')
      }

      let sticker = response.data.sticker

      if (sticker.errors.length > 0) {
        throw new Error(sticker.errors.shift())
      }

      return sticker
    })
}

const reorder = (stickers) => {
  return httpClient.post('/stickers/reorder/', { orders: stickers })
    .then(response => {
      if (typeof response.data.stickers === 'undefined') {
        throw new Error('Request failed')
      }

      return response.data.stickers.filter(el => el.errors.length === 0)
    })
}

export {
  listAll,
  add,
  edit,
  remove,
  reorder
}
